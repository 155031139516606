var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticStyle:{"transition":"all .5s ease"}},[_c('div',{staticClass:"modal d-flex justify-content-end"},[_c('div',{staticClass:"bg-white p-2 w-50 overflow-y-scroll"},[_c('div',{staticClass:"d-flex align-items-center pb-1 border-b mb-1"},[_c('strong',{staticClass:"my-0 text-dark text-10"},[_vm._v("Lengkapi Informasi Assignment")]),_c('span',{staticClass:"ml-auto k-close-circle cursor-pointer text-10",on:{"click":function($event){return _vm.$emit('close')}}})]),_c('b-overlay',{attrs:{"variant":"light","show":_vm.loading,"spinner-variant":"primary","blur":"0","opacity":".5","rounded":"sm"}},[_c('validation-observer',{ref:"formRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Nama Talent ")]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Nama Talent","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (option) { return option; },"options":_vm.listTalent,"placeholder":"Ketik untuk mencari..."},on:{"search":function (search) { return search.length > 0 ? _vm.onSearchTalent(search) : ''; }},model:{value:(_vm.talent),callback:function ($$v) {_vm.talent=$$v},expression:"talent"}}),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Nama Partner ")]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Nama Partner","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (option) { return option; },"options":_vm.listPartner,"placeholder":"Ketik untuk mencari..."},on:{"search":function (search) { return search.length > 0 ? _vm.onSearchPartner(search) : ''; },"input":_vm.handleInput},model:{value:(_vm.partner),callback:function ($$v) {_vm.partner=$$v},expression:"partner"}}),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Leader ")]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Leader","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option; },"options":_vm.listLeader,"placeholder":"Ketik untuk mencari..."},on:{"search":function (search) { return search.length > 0 ? _vm.onSearchLeader(search) : ''; }},model:{value:(_vm.leader),callback:function ($$v) {_vm.leader=$$v},expression:"leader"}}),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Device ")]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Device","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"device_name","reduce":function (option) { return option; },"options":_vm.listDevice,"placeholder":"Ketik untuk mencari...","disabled":!_vm.partner ? true : false},model:{value:(_vm.device),callback:function ($$v) {_vm.device=$$v},expression:"device"}}),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-class":"font-medium text-7 text-black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Kantor ")]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Kantor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"working_space_name","reduce":function (option) { return option; },"options":_vm.listOffice,"placeholder":"Ketik untuk mencari..."},model:{value:(_vm.office),callback:function ($$v) {_vm.office=$$v},expression:"office"}}),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-end mt-auto"},[_c('button',{staticClass:"button-primary w-[200px] py-[8px]",attrs:{"disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.submit()}}},[_vm._v(" Tambahkan ")])])]}}])})],1)],1)]),_c('div',{staticClass:"modal-backdrop"})])}
var staticRenderFns = []

export { render, staticRenderFns }